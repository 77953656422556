// tslint:disable-next-line:max-line-length
import { Component, Injectable, Input, ElementRef, OnInit, ViewChild, ViewChildren, AfterViewInit, QueryList, EventEmitter, Output} from '@angular/core';
import { MissionService } from '../service/mission.service';
declare var lottie: any;

@Component({
  templateUrl: './weila.component.html',
  styleUrls: ['./weila.component.css']
})

@Injectable()

export class WeilaComponent implements AfterViewInit, OnInit {

  // @Output() sendIndexArray = new EventEmitter();
  // @ViewChildren('scrollContainer') scrollContainer: ElementRef;

  @ViewChildren('indexSelector') indexSelector: QueryList<any>;

  colorArray = ['#ff9dc0', '#cffcbf', '#ff3131', '#5fa9ff', '#9e9e9e'];

  longSample_Anim: any;
  neutral_Anim: any;
  cry_Anim: any;
  happy_Anim: any;
  playful_Anim: any;
  angry_Anim: any;
  angry_old_Anim: any;

  isMobile = false;

  constructor(
    private elRef: ElementRef,
    private _missionService: MissionService,
  ) {
    _missionService.weilaEmotionAnnouncedSource$.subscribe(
      value => {
        // console.log('got emotion event in weilapage');
        // console.log(value);
        if ( value === 1 ) {
          this.goHappy();
        } else if (value === 2) {
          this.goAngry();
        } else if (value === 3) {
          this.goNeutral();
        } else if (value === 4) {
          this.goSad();
        }

      }
    );
  }

  ngOnInit(): void {
    this.initAnimations();
    // console.log('init');
    // device detection
    // tslint:disable-next-line:max-line-length
    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.isMobile = true;
      document.getElementById('handleText').style.lineHeight = 'normal';
      document.getElementById('handleText').style.fontSize = '10px';
      document.getElementById('handleText').style.position = 'absolute';
      document.getElementById('handleText').style.left = '0px';
      document.getElementById('handleText').style.top = '20px';

      // console.log('isMobile');
    } else {
      // console.log('notMobile');
      this.isMobile = false;
    }
  }

  calculateIndexPosition(indexSelector) {
    const indexArray = [];
    indexSelector.forEach(element => {
      // console.log(element.nativeElement.offsetTop);
      indexArray.push(element.nativeElement.offsetTop);
    });
    // console.log(indexArray);
    // this.sendIndexArray.emit(indexArray);
    this._missionService.announceIndexArray(indexArray);

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateIndexPosition(this.indexSelector);
    }, 100);
  }


  initAnimations() {
    this.longSample();
    this.cry();
    this.playful();
    this.happy();
    this.angry();
    this.sleep();
    this.security();
  }


  longSample(): void {
    // // console.log('play animation');
      const params = {
            container: document.getElementById('longSample'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
    // 		animationData: data
        path: '../../assets/animation/longSample_nobg_new_data.json'
        };

    this.longSample_Anim = lottie.loadAnimation(params);
  }

  neutral(): void {
    // // console.log('play animation');
      const params = {
            container: document.getElementById('neutral'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
    // 		animationData: data
        path: '../../assets/animation/neutral_data.json'
        };

    this.neutral_Anim = lottie.loadAnimation(params);
  }

  angry(): void {
    // // console.log('play animation');
      const params = {
            container: document.getElementById('angry'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
    // 		animationData: data
        path: '../../assets/animation/angry_data.json'
        };

    this.angry_Anim = lottie.loadAnimation(params);
  }

  security(): void {
    // // console.log('play animation');
      const params = {
            container: document.getElementById('security'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
    // 		animationData: data
        path: '../../assets/animation/security_data.json'
        };

    this.neutral_Anim = lottie.loadAnimation(params);
  }

  sleep(): void {
    // // console.log('play animation');
      const params = {
            container: document.getElementById('sleep'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
    // 		animationData: data
        path: '../../assets/animation/sleep_data.json'
        };

    this.neutral_Anim = lottie.loadAnimation(params);
  }

  playful(): void {
    // // console.log('play animation');
      const params = {
            container: document.getElementById('playful'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
    // 		animationData: data
        path: '../../assets/animation/pleadForPlay_data.json'
        };

    this.playful_Anim = lottie.loadAnimation(params);
  }

  happy(): void {
    // // console.log('play animation');
      const params = {
            container: document.getElementById('alarm'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
    // 		animationData: data
        path: '../../assets/animation/alarm_data.json'
        };

    this.happy_Anim = lottie.loadAnimation(params);
  }

  cry(): void {
    // // console.log('play animation');
      const params = {
            container: document.getElementById('cry'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
    // 		animationData: data
        path: '../../assets/animation/cry_data.json'
        };

    this.cry_Anim = lottie.loadAnimation(params);
  }


  initLongSample() {
    this.longSample_Anim.stop();
    document.getElementById('controlHandle').style.top = (document.getElementById('controlPanel').offsetHeight - 60) / 2  + 'px';
    document.getElementById('controlHandle').style.left = (document.getElementById('controlPanel').offsetWidth - 60) / 2  + 'px';
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[4];
    // this.longSample_Anim.playSegments([[0, 100], [101, 190]], true);
    this.longSample_Anim.playSegments([0, 653], true);
  }

  goNeutral() {
    document.getElementById('goNeutral').focus();
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[1];
    this.longSample_Anim.stop();
    this.longSample_Anim.playSegments([101, 190], true);
  }

  moveToNeutral() {
    document.getElementById('goNeutral').focus();
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[1];
    document.getElementById('controlHandle').style.top = (document.getElementById('controlPanel').offsetHeight * 1.5 - 60) / 2  + 'px';
    document.getElementById('controlHandle').style.left = (document.getElementById('controlPanel').offsetWidth / 2 - 60) / 2 + 'px';
    this.longSample_Anim.stop();
    this.longSample_Anim.playSegments([101, 190], true);
  }

  goHappy() {
    document.getElementById('goHappy').focus();
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[0];
    this.longSample_Anim.stop();
    this.longSample_Anim.playSegments([200, 290], true);
  }

  moveToHappy() {
    document.getElementById('goHappy').focus();
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[0];
    document.getElementById('controlHandle').style.top = (document.getElementById('controlPanel').offsetHeight / 2 - 60) / 2 + 'px';
    document.getElementById('controlHandle').style.left = (document.getElementById('controlPanel').offsetWidth / 2 - 60) / 2 + 'px';
    this.longSample_Anim.stop();
    this.longSample_Anim.playSegments([200, 290], true);
  }

  goSad() {
    document.getElementById('goSad').focus();
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[3];
    this.longSample_Anim.stop();
    this.longSample_Anim.playSegments([441, 531], true);
  }

  moveToSad() {
    document.getElementById('goSad').focus();
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[3];
    document.getElementById('controlHandle').style.top = (document.getElementById('controlPanel').offsetHeight * 1.5 - 60) / 2  + 'px';
    document.getElementById('controlHandle').style.left = (document.getElementById('controlPanel').offsetWidth * 1.5 - 60) / 2  + 'px';
    this.longSample_Anim.stop();
    this.longSample_Anim.playSegments([441, 531], true);
  }

  goAngry() {
    document.getElementById('goAngry').focus();
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[2];
    this.longSample_Anim.stop();
    this.longSample_Anim.playSegments([598, 653], true);
  }

  moveToAngry() {
    document.getElementById('goAngry').focus();
    document.getElementById('controlHandle').style.backgroundColor = this.colorArray[2];
    document.getElementById('controlHandle').style.top = (document.getElementById('controlPanel').offsetHeight / 2 - 60) / 2 + 'px';
    // document.getElementById('controlHandle').style.top = '30px';
    document.getElementById('controlHandle').style.left = (document.getElementById('controlPanel').offsetWidth * 1.5 - 60) / 2  + 'px';
    // document.getElementById('controlHandle').style.left = '130px';
    this.longSample_Anim.stop();
    this.longSample_Anim.playSegments([598, 653], true);
  }

  dragEventHandlePrevent(event) {
    // console.log('stopped');
    event.preventDefault();
  }
}
