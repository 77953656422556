import { Directive, ElementRef, HostListener, Input, OnInit, DoCheck} from '@angular/core';

@Directive({
  selector: '[appToggleSize]',

})

export class ToggleSizeDirective implements OnInit, DoCheck {
  constructor(
    private elRef: ElementRef
  ) {

  }

  size = false;

  @Input() detailTarget;

  @HostListener('click') onmouseenter() {

    // if (this.detailTarget === this.elRef.nativeElement.id) {

    // }
    this.size = !this.size;
    if (this.size) {
      // console.log('bigger');
      this.bigSize();
    } else {
      // console.log('smaller');
      this.smallSize();
    }

  }

  ngDoCheck(): void {

  }

  ngOnInit(): void {
    // console.log('ToggleSize directive init');

  }

  bigSize(): void {
    this.elRef.nativeElement.style.transitionDuration = '0.2s';
    this.elRef.nativeElement.style.width = '45vw';
    this.elRef.nativeElement.style.height = '45vh';
    this.elRef.nativeElement.style.top = (window.innerHeight - window.innerHeight * 0.45) / 2 + 'px';
    this.elRef.nativeElement.style.left = (window.innerWidth * 0.6 - window.innerWidth * 0.45) / 2 + 'px';
  }

  smallSize(): void {
    this.elRef.nativeElement.style.width = '50vw';
    this.elRef.nativeElement.style.height = '40vh';
    this.elRef.nativeElement.style.top = (window.innerHeight - window.innerHeight * 0.4) / 2 + 'px';
    this.elRef.nativeElement.style.left = (window.innerWidth * 0.6 - window.innerWidth * 0.5) / 2 + 'px';
    this.elRef.nativeElement.style.transitionDuration = '0.5s';
  }
}
