import {trigger, animate, transition, style, state} from '@angular/animations';

export const scrollAnimation = trigger('scrollAnimation',
    [
        state('center', style({
            'transform': 'translateY(0px)',
            'opacity': '1',
            'visibility': 'visible',
        })),
        state('left', style({
            'transform': 'translateY(-400px)',
            'opacity': '0',
            'visibility': 'hidden',
            'pointer-events': 'none'
        })),
        state('right', style({
            'transform': 'translateY(400px)',
            'opacity': '0',
            'visibility': 'hidden',
            'pointer-events': 'none'
        })),
        transition('center => *', animate('0ms ease-in-out')),
        transition('* => center', animate('600ms ease-in-out'))
    ]
);

export const navTitle = trigger('navTitle',
  [
    state('selected', style({
        'transform': '{{ transform }}'
      }),
      { params: { 'transform': '{{ transform }}'}},
    ),
      state('normal', style({
        'top': '0px'
    })),
    state('close', style({
        'top': '0px',
        'opacity': '0'
    })),
    transition('* => *', animate('300ms ease-in-out')),
  ]
);
