import { Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import { MissionService } from '../service/mission.service';

@Directive({
  selector: '[appDrag]',

})

export class DragDirective implements OnInit {
    draggable = false;
    originClientX = 0;
    originClientY = 0;
    movementY = 0;
    movementX = 0;

    handleX;
    handleY;

    handleW;
    handleH;

    emotion = 0;

    @HostListener('mousedown', ['$event']) onmousedown(event) {
        if (event.target.id === 'controlHandle') {
            // console.log('drag begin');
            // console.log(event);
            this.draggable = true;
            this.originClientY = event.clientY;
            this.originClientX = event.clientX;
            this.handleX = this.elRef.nativeElement.firstChild.offsetLeft;
            this.handleY = this.elRef.nativeElement.firstChild.offsetTop;
            this.handleW = this.elRef.nativeElement.firstChild.offsetWidth;
            this.handleH = this.elRef.nativeElement.firstChild.offsetHeight;
            this.elRef.nativeElement.firstChild.style.transition = 'none';
            // console.log(this.originClientY);
        } else if ( event.target.id === 'handleText' ) {
            this.draggable = true;
            event.preventDefault();
            // console.log('drag begin');
            // console.log(event);
            this.originClientY = event.clientY;
            this.originClientX = event.clientX;
            this.handleX = this.elRef.nativeElement.firstChild.offsetLeft;
            this.handleY = this.elRef.nativeElement.firstChild.offsetTop;
            this.handleW = this.elRef.nativeElement.firstChild.offsetWidth;
            this.handleH = this.elRef.nativeElement.firstChild.offsetHeight;
            this.elRef.nativeElement.firstChild.style.transition = 'none';
            // console.log(this.originClientY);
        }
    }

    @HostListener('mouseup', ['$event']) onmouseup(event) {
        // console.log('drag end');
        this.elRef.nativeElement.firstChild.style.transition = 'all 0.2s ease-in-out';
        this.draggable = false;
        // // console.log(event);
        this.originClientY = event.clientY;
        this.originClientX = event.clientX;
        // // console.log(this.handleX);
    }

    @HostListener('mousemove', ['$event']) onmousemove(event) {
        if (this.draggable) {
            if (event.target.id === 'controlHandle' || event.target.id === 'handleText') {
                event.stopPropagation();
                // console.log('drag moving');
                this.movementY = event.clientY - this.originClientY;
                this.movementX = event.clientX - this.originClientX;
                // // console.log(this.movementX);
                // // console.log(this.movementY);
                this.drag();
            }

        }

    }

    @HostListener('mouseleave') onmouseleave(event) {
        // console.log('drag end');
        this.elRef.nativeElement.firstChild.style.transitionDuration = 'all 0.2s ease-in-out';
        this.draggable = false;
        // console.log(event);
    }

    constructor(
        private elRef: ElementRef,
        private _missionService: MissionService,
    ) {
    }

    ngOnInit() {
        // tslint:disable-next-line:max-line-length
        this.elRef.nativeElement.firstChild.style.left = (this.elRef.nativeElement.offsetWidth - this.elRef.nativeElement.firstChild.offsetWidth) / 2 + 'px';
        // tslint:disable-next-line:max-line-length
        this.elRef.nativeElement.firstChild.style.top = (this.elRef.nativeElement.offsetHeight - this.elRef.nativeElement.firstChild.offsetHeight) / 2 + 'px';
    }

    drag() {
        if ( (this.handleX + this.movementX ) > (this.elRef.nativeElement.offsetWidth - this.elRef.nativeElement.firstChild.offsetWidth) ) {
            // tslint:disable-next-line:max-line-length
            this.elRef.nativeElement.firstChild.style.left = this.elRef.nativeElement.offsetWidth - this.elRef.nativeElement.firstChild.offsetWidth + 'px';
        // tslint:disable-next-line:max-line-length
        } else if ( this.handleX + this.movementX  < 0 ) {
            this.elRef.nativeElement.firstChild.style.left = '0px';
        } else {
            this.elRef.nativeElement.firstChild.style.left = this.handleX + this.movementX + 'px';
        }

        // tslint:disable-next-line:max-line-length
        if ( (this.handleY + this.movementY ) > (this.elRef.nativeElement.offsetHeight - this.elRef.nativeElement.firstChild.offsetHeight) ) {
            // tslint:disable-next-line:max-line-length
            this.elRef.nativeElement.firstChild.style.top = this.elRef.nativeElement.offsetHeight - this.elRef.nativeElement.firstChild.offsetHeight + 'px';
        // tslint:disable-next-line:max-line-length
        } else if ( this.handleY + this.movementY  < 0 ) {
            this.elRef.nativeElement.firstChild.style.top = '0px';
        } else {
            this.elRef.nativeElement.firstChild.style.top = this.handleY + this.movementY + 'px';
        }

        const x = this.elRef.nativeElement.firstChild.offsetLeft;
        const y = this.elRef.nativeElement.firstChild.offsetTop;
        const w = this.elRef.nativeElement.offsetWidth;
        const h = this.elRef.nativeElement.offsetHeight;
        // console.log(x);


        if (x < w / 2 - this.handleW / 2 && y < h / 2 - this.handleH / 2) {
            // console.log(1);
            if (this.emotion !== 1) {
                this.emotion = 1;
                this.emitSelection(1);
            }


        } else if (x > w / 2 - this.handleW / 2 && y < h / 2 - this.handleH / 2) {
            // console.log(2);
            if (this.emotion !== 2) {
                this.emotion = 2;
                this.emitSelection(2);
            }

        } else if (x < w / 2 - this.handleW / 2 && y > h / 2 - this.handleH / 2 ) {
            // console.log(3);
            if (this.emotion !== 3) {
                this.emotion = 3;
                this.emitSelection(3);
            }

        } else if (x > w / 2 - this.handleW / 2 && y > h / 2 - this.handleH / 2 ) {
            // console.log(4);
            if (this.emotion !== 4) {
                this.emotion = 4;
                this.emitSelection(4);
            }
        }
    }

    emitSelection(area) {
        this._missionService.announceWeilaEmotion(area);
    }

}
