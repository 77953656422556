// tslint:disable-next-line:max-line-length
import { Component, Injectable, Input, ElementRef, OnInit, ViewChild, ViewChildren, AfterViewInit, QueryList, EventEmitter, Output} from '@angular/core';
import { MissionService } from '../service/mission.service';
declare var lottie: any;

@Component({
  templateUrl: './meowwoof.component.html',
  styleUrls: ['./meowwoof.component.css']
})

@Injectable()

export class MeowwoofComponent implements AfterViewInit, OnInit {

  // @Output() sendIndexArray = new EventEmitter();
  // @ViewChildren('scrollContainer') scrollContainer: ElementRef;

  @ViewChildren('indexSelector') indexSelector: QueryList<any>;

  longSample_Anim: any;
  neutral_Anim: any;
  cry_Anim: any;
  happy_Anim: any;
  playful_Anim: any;

  // tslint:disable-next-line:max-line-length
  iconList = ['1c', '1d', '2c', '2d', '3c', '3d', '4c', '4d', '5c', '5d', '6c', '6d', '7c', '7d'];

  icon1;
  icon2;
  icon3;
  icon4;
  icon5;
  icon6;
  icon7;
  icon8;
  icon9;
  icon10;
  icon11;
  icon12;
  icon13;
  icon14;

  constructor(
    private elRef: ElementRef,
    private _missionService: MissionService,
  ) {

  }
  ngOnInit(): void {
    // console.log('init');
  }

  calculateIndexPosition(indexSelector) {
    const indexArray = [];
    indexSelector.forEach(element => {
      // console.log(element.nativeElement.offsetTop);
      indexArray.push(element.nativeElement.offsetTop);
    });
    // console.log(indexArray);
    // this.sendIndexArray.emit(indexArray);
    this._missionService.announceIndexArray(indexArray);

  }

  ngAfterViewInit(): void {
    // console.log(this.indexSelector);
    setTimeout(() => {
      this.calculateIndexPosition(this.indexSelector);
      this.iconAnimations();
      this.processBar();
    }, 10);
  }

  iconAnimations() {

    for (const iconId of this.iconList) {
      const iconParam = {
        container: document.getElementById(iconId),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '../../assets/animation/meowwoof/' + iconId + '_data.json'
      };

      lottie.loadAnimation(iconParam);
    }

  }

  processBar() {
    const processBarParam = {
      container: document.getElementById('processBar'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../assets/animation/meowwoof/processBar_data.json'
    };

    lottie.loadAnimation(processBarParam);

    const stripeParam = {
      container: document.getElementById('stripe'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../assets/animation/meowwoof/stripe_data.json'
    };

    lottie.loadAnimation(stripeParam);
  }


}
