// tslint:disable-next-line:max-line-length
import { Component, Injectable, Input, ElementRef, OnInit, ViewChild, ViewChildren, AfterViewInit, QueryList, EventEmitter, Output} from '@angular/core';
import { MissionService } from '../service/mission.service';
declare var lottie: any;

@Component({
  templateUrl: './awsXray.component.html',
  styleUrls: ['./awsXray.component.css']
})

@Injectable()

export class AwsXrayComponent implements AfterViewInit, OnInit {

  // @Output() sendIndexArray = new EventEmitter();
  // @ViewChildren('scrollContainer') scrollContainer: ElementRef;

  @ViewChildren('indexSelector') indexSelector: QueryList<any>;

  constructor(
    private elRef: ElementRef,
    private _missionService: MissionService,
  ) {

  }
  ngOnInit(): void {

  }
  calculateIndexPosition(indexSelector) {
    const indexArray = [];
    indexSelector.forEach(element => {
      // console.log(element.nativeElement.offsetTop);
      indexArray.push(element.nativeElement.offsetTop);
    });
    // console.log(indexArray);
    // this.sendIndexArray.emit(indexArray);
    this._missionService.announceIndexArray(indexArray);

  }

  ngAfterViewInit(): void {
    // console.log(this.indexSelector);
    this.calculateIndexPosition(this.indexSelector);
  }

}
