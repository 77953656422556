import { Component, Injectable, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {trigger, animate, transition, style, state} from '@angular/animations';

import { MissionService } from '../service/mission.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  animations: [
    trigger('fadeIn_upside',
    [
      state('hide', style({
        'transform': 'translateY(-400px)',
        'opacity': '0'
      })),
      state('show', style({
        'transform': 'translateY(0px)',
        'opacity': '1'
      })),
      transition('hide => *', animate('400ms ease-in-out'))
    ]
    )
  ],
})

@Injectable()

export class AboutComponent implements OnInit {

  init = 'hide';

  ngOnInit(): void {
    setTimeout(() => {
      this.init = 'show';
    }, 100);
  }

}
