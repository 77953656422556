import { Directive, ElementRef, HostListener, Input, OnInit, DoCheck, HostBinding} from '@angular/core';

@Directive({
  selector: '[appCenterCenter]',

})

export class CenterCenterDirective implements OnInit, DoCheck {
    constructor(
        private elRef: ElementRef
      ) {}

    @Input() windowSize;

    @Input() centerable;

    @Input() backgroundUrl;

    @HostBinding('style.top') top;
    @HostBinding('style.left') left;

    ngOnInit(): void {
        // console.log('appCenterCenter init');
        // window.onresize = () => {
        //     // console.log('window resizing');

        // };
        // console.log('centerable' + this.centerable);
        setTimeout(() => {
            if (this.centerable) {
                this.top = (this.windowSize.innerHeight - this.windowSize.innerHeight * 0.4) / 2 + 'px';
                this.left = (this.windowSize.innerWidth * 6 / 10 - this.elRef.nativeElement.offsetWidth) / 2 + 'px';
            }

        }, 0);

    }

    ngDoCheck(): void {

    }
}
