import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DetailPageComponent } from './detailPage/detailPage.component';
import { SidePageComponent } from './sidePage/sidePage.component';

import {AwsXrayComponent} from './awsXray/awsXray.component';
import {AwsXraySideComponent} from './awsXraySide/awsXraySide.component';

import {KitchenupComponent} from './kitchenup/kitchenup.component';
import {KitchenupSideComponent} from './kitchenupSide/kitchenupSide.component';

import {WeilaComponent} from './weila/weila.component';
import {WeilaSideComponent} from './weilaSide/weilaSide.component';

import {MeowwoofComponent} from './meowwoof/meowwoof.component';
import {MeowwoofSideComponent} from './meowwoofSide/meowwoofSide.component';

import {SwanlakeComponent} from './swanlake/swanlake.component';
import {SwanlakeSideComponent} from './swanlakeSide/swanlakeSide.component';

const routes: Routes = [
    { path: '', redirectTo: 'main', pathMatch: 'full' },
    { path: 'main', component: HomeComponent},
    { path: 'home', component: AppComponent},

    { path: 'awsXray', component: AwsXrayComponent, outlet: 'project'},
    { path: 'kitchenup', component: KitchenupComponent, outlet: 'project'},
    { path: 'weila', component: WeilaComponent, outlet: 'project'},
    { path: 'meowwoof', component: MeowwoofComponent, outlet: 'project'},
    { path: 'swanlake', component: SwanlakeComponent, outlet: 'project'},

    { path: 'awsXray', component: AwsXraySideComponent, outlet: 'sideDetail'},
    { path: 'kitchenup', component: KitchenupSideComponent, outlet: 'sideDetail'},
    { path: 'weila', component: WeilaSideComponent, outlet: 'sideDetail'},
    { path: 'meowwoof', component: MeowwoofSideComponent, outlet: 'sideDetail'},
    { path: 'swanlake', component: SwanlakeSideComponent, outlet: 'sideDetail'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
