import { Component, Injectable, Input, ElementRef, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate} from '@angular/animations';
import { MissionService } from '../service/mission.service';

@Component({
  selector: 'app-side-page',
  templateUrl: './sidePage.component.html',
  styleUrls: ['./sidePage.component.css'],
  animations: [
    trigger('fadeIn_downside',
    [
      state('hide', style({
        'transform': 'translateY(400px)',
        'opacity': '0'
      })),
      state('show', style({
        'transform': 'translateY(0px)',
        'opacity': '1'
      })),
      transition('* <=> *', animate('400ms ease-in-out'))
    ]
    )
  ],
})

@Injectable()

export class SidePageComponent implements OnInit {

  @Input() projectName;
  @Input() scrollPosition;

  @ViewChild('sidePageScrollContainer') sidePageScrollContainer: ElementRef;

  constructor(
    private elRef: ElementRef,
    private _missionService: MissionService,
  ) {
  //   _missionService.missionAnnounced$.subscribe(
  //   value => {
  //     // console.log('got event in sidePage');
  //     // console.log(value);

  //     this.scrollTo(value);
  //     if ( value === 'show') {


  //     } else if (value === 'hide') {

  //     }

  //   }
  // );
  }

  show = 'hide';

  ngOnInit(): void {
    // console.log(this.projectName);
    setTimeout(() => {
      this.show = 'show';
    }, 1000);

  }

  scrollTo(position) {
    // setTimeout(() => {
    //   this.sidePageScrollContainer.nativeElement.scrollTo(0, position);
    // }, 1000);
    // let target;
    // this.sidePageScrollContainer.nativeElement.scrollIntoView();
  }


}
