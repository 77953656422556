import { Component, Injectable, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail-page',
  templateUrl: './detailPage.component.html',
  styleUrls: ['./detailPage.component.css']
})

@Injectable()

export class DetailPageComponent implements OnInit {

  // @Input() projectName;

  constructor(
    private _router: Router,
    private _aRouter: ActivatedRoute,
  ) {}

  projectName = this._aRouter.snapshot.queryParams['projectName'];

  ngOnInit(): void {
    // console.log('detail init');
    // console.log(this.projectName);

  }

}
