import { Component, Injectable, EventEmitter, OnInit, DoCheck, ViewChild, ElementRef, AfterViewChecked, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import {scrollAnimation, navTitle} from '../animation/scrollAnimation';
import { MissionService } from '../service/mission.service';

declare var lottie: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    scrollAnimation,
    navTitle,
  ],
})

@Injectable()

export class HomeComponent implements OnInit, AfterViewChecked {

  @ViewChild('selector') selector: ElementRef;
  @ViewChild('detailContainer') detailContainer: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(
    private _router: Router,
    private _missionService: MissionService,
  ) {
    _missionService.missionAnnounced$.subscribe(
      value => {
        // console.log('got event in homePage');
        // // console.log(value);
        if ( value === 'show') {


        } else if (value === 'hide') {

        }

      }
    );

    _missionService.indexArrayAnnouncedSource$.subscribe(
      value => {
        // console.log('got arrayIndex from detailPage');
        this.updateIndexArray(value);
      }
    );
  }

  titleTransform = 'translateY(0px)';

  elementArray = ['center', 'right', 'right', 'right', 'right'];

  animationArray = ['done', 'done', 'done', 'done', 'done'];

  projectTitleAnimation = ['normal', 'normal', 'normal', 'normal', 'normal'];

  // tslint:disable-next-line:max-line-length
  projectArray = [
    {
      name: 'AWS X-Ray',
      sub: 'Distributed Tracing System',
      job: 'React & D3.js & webpack',
      url: 'awsXray',
      coverUrl: '../../assets/images/awsXrayCover.png'
    },
    {
      name: 'Kitchen Up',
      sub: 'MOBILE APPLICATION',
      job: 'Angular 6 & NativeScript & TypeScript & JavaScript',
      url: 'kitchenup',
      coverUrl: '../../assets/images/kitchenupCover.jpg'
    },
    {
      name: 'WEILA 3.0',
      sub: 'HOME ASSISTANT ROBOT',
      job: 'JavaScript & Motion Graphic',
      url: 'weila',
      coverUrl: '../../assets/videos/weila.mp4'
    },
    {
      name: 'Meow & Woof',
      sub: 'WEB APP DEVELOPMENT',
      job: 'JavaScript & JQuery', url: 'meowwoof',
      coverUrl: '../../assets/images/Meow_Woof_Main_Page.png'
    },
    {
      name: 'Swan Lake',
      sub: 'REAL ESTATE 3D COMMERCIAL',
      job: 'Maya & Python',
      url: 'swanlake',
      coverUrl: '../../assets/videos/swanlake_1.mp4'
    },
  ];

  about = false;

  detail = false;

  detailStep = 0;

  detailTarget = null;

  wiggleable = true;

  traceable = true;

  interactable = true;

  wheelNumber = 0;

  cursorPosition = { x: window.innerWidth / 2 - 100, y : window.innerHeight / 2 - 100};

  scrollable = true;

  scrollPosition = 0;

  windowSize = {innerWidth: window.innerWidth, innerHeight: window.innerHeight };

  detailScrollIndex = [0, 300, 600, 900];

  step = 0;

  scrollCursor = 0;

  projectName: string;

  mobile = false;

  titileContainerHeight: number;

  @HostListener('window:resize') onResize() {
    // guard against resize before view is rendered
    // // console.log('window resizing');

    this.getTitleContainerHeight();

    this.windowSize.innerHeight = window.innerHeight;
    this.windowSize.innerWidth = window.innerWidth;

    if ( window.innerWidth <= 900 ) {

      // console.log('small screen!');

      this.mobile = true;

      if (!this.detail) {
        this.selector.nativeElement.style.top = this.titileContainerHeight * this.step + 100 + 'px';
      } else {
        this.titleTransform = 'translateY(' + ((-1 * this.titileContainerHeight * this.step) + 57) + 'px)';
        this.selector.nativeElement.style.top = 57 + 'px';
      }

    } else if ( window.innerWidth > 900) {

      this.mobile = false;

      if (!this.detail) {
        this.selector.nativeElement.style.top = this.titileContainerHeight * this.step + 100 + 'px';
      } else {
        this.titleTransform = 'translateY(' + ((-1 * this.titileContainerHeight * this.step) + 32) + 'px)';
        this.selector.nativeElement.style.top = 32 + 'px';
      }

      // console.log('desktop!');

    }

  }

  ngOnInit(): void {
    // console.log('init');
    setTimeout(() => {
      this.getTitleContainerHeight();
    }, 0);


    if ( window.innerWidth <= 900 ) {
      this.mobile = true;
      // console.log('mobile!');
    }

    setTimeout(() => {
      const promise = document.querySelector('video').play();

      if (promise !== undefined) {
        promise.then(_ => {
          // Autoplay started!
        }).catch(error => {
          // console.log('Autoplay was prevented');


        });
      }
    }, 0);

  }

  ngAfterViewChecked(): void {


  }

  getMousePosition(event) {
    this.cursorPosition.x = event.clientX;
    this.cursorPosition.y = event.clientY;
    // // console.log(this.cursorPosition);
  }

  getTitleContainerHeight() {
    const titileContainer = document.getElementById('project_0');
    const currentStyle = window.getComputedStyle(titileContainer);
    const marginTop = currentStyle.marginTop.replace(/px/gi, '');
    const marginBottom = currentStyle.marginBottom.replace(/px/gi, '');
    const outerHeight = parseFloat(marginTop) + parseFloat(marginBottom);
    this.titileContainerHeight = titileContainer.offsetHeight + outerHeight;
  }

  getWheelEvent(event) {
    // console.log('case1');
    // console.log(this.scrollable);

    if (this.scrollable && !this.detail && !this.about) {
      // console.log('case1_1');

      this.wheelNumber = event.deltaY;
      // console.log(this.scrollable);
      // console.log(event.deltaY);
      if (event.deltaY > 0) {
        this.step++;
      } else {
        this.step--;
      }

      if (this.step > (this.elementArray.length - 1)) {
        this.step = this.elementArray.length - 1;
      } else if (this.step < 0) {
        this.step = 0;
      }


      // console.log(this.titileContainerHeight);

      if (window.innerWidth <= 900) {
        this.selector.nativeElement.style.top = this.titileContainerHeight * this.step + 100 + 'px';
      } else {
        this.selector.nativeElement.style.top = this.titileContainerHeight * this.step + 100 + 'px';
      }

      // console.log('step' + this.step);

      for (let i = 0; i < this.elementArray.length; i++) {

        if (i < this.step) {
          this.elementArray[i] = 'left';
        } else if ( i > this.step ) {
          this.elementArray[i] = 'right';
        } else {
          this.elementArray[i] = 'center';
        }

      }


    } else {
      // console.log('case1_2');
      // console.log('step' + this.step);
      // if (event.deltaY > 0) {
      //   this.detailStep++;
      // } else {
      //   this.detailStep--;
      // }

      // if (this.detailStep < 0) {
      //   this.detailStep = 0;
      // }

      // if (this.detailStep >  5) {
      //   this.detailStep = 5;
      // }
      // // console.log(this.detailStep);

    }

  }

  animationEnd(index) {
    this.animationArray[index] = 'done';

    if (this.animationArray.indexOf('moving') === -1) {
      this.scrollable = true;
    }
    // console.log('animation end');
  }

  animationStart(index) {
    this.animationArray[index] = 'moving';
    // console.log('animation start');
    this.scrollable = false;
  }

  toDetail(event) {
    event.stopPropagation();
    const id = event.target.id;
    this.projectName = id;
    // // console.log(event.target.parentElement);
    const index = id.split('_')[1];
    const _index = Number(index);
    // console.log(_index);
    if (_index === 2) {
      const video1 = <HTMLMediaElement>document.getElementById('cover_2');
      video1.play();
    } else if (_index === 4 ) {
      const video2 = <HTMLMediaElement>document.getElementById('cover_4');
      video2.play();
    }

    if (!this.detail) {
      this.detail = true;
      if (this.mobile) {
        this.selector.nativeElement.style.top = 57 + 'px';
      } else {
        this.selector.nativeElement.style.top = 32 + 'px';
      }

      this.scrollContainer.nativeElement.style.overflowY = 'scroll';
      event.target.parentElement.style.transitionDuration = '0s';
      this.detailTarget = id;
      this.wiggleable = false;
      this.traceable = false;
      this.interactable = false;
      this.scrollable = false;

      for (let i = 0; i < this.projectArray.length; i++) {
        if (i === this.step) {
          if (this.mobile) {
            this.titleTransform = 'translateY(' + ((-1 * this.titileContainerHeight * i) + 57) + 'px)';
          } else {
            this.titleTransform = 'translateY(' + ((-1 * this.titileContainerHeight * i) + 32) + 'px)';
          }
          this.projectTitleAnimation[i] = 'selected';
        } else {
          this.projectTitleAnimation[i] = 'close';
        }

      }
      // this._router.navigate(['/home/main'], {queryParams: { projectName : id}});
      // tslint:disable-next-line:max-line-length

      event.target.parentElement.style.top = (window.innerHeight -  event.target.parentElement.style.offsetHeight) / 2 + 'px';
      event.target.parentElement.style.left = (window.innerWidth * 0.6 - window.innerWidth * 0.5) / 2 + 'px';
      event.target.parentElement.style.width = '50vw';
      event.target.parentElement.style.height = 'auto';
      event.target.parentElement.style.transform = 'rotateX(0deg) rotateY(0deg)';
      this._router.navigate([ {outlets: { project: [this.projectArray[index].url], sideDetail: [this.projectArray[index].url]}} ]);



    }

  }


  closeDetail(event) {
    event.stopPropagation();
    this.scrollContainer.nativeElement.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    // // console.log(event);
    if (this.detail) {

      const id = event.target.id;
      this.detail = false;
      this.projectName = id;

      // // console.log(event.target.parentElement.previousElementSibling);
      this.selector.nativeElement.style.top = this.titileContainerHeight * this.step + 100 + 'px';
      this.scrollContainer.nativeElement.style.overflowY = 'hidden';
      event.target.parentElement.parentElement.style.width = '60vw';
      this.wiggleable = true;
      this.traceable = true;
      this.interactable = true;
      this.scrollable = true;

      for (let i = 0; i < this.projectArray.length; i++) {

        this.projectTitleAnimation[i] = 'normal';

      }

      event.target.parentElement.parentElement.style.transitionDuration = '0.5s';

      this._router.navigate([{outlets: { project: null , sideDetail: null }} ]);

    }

  }

  test(event) {
    // console.log('did');
    this.projectTitleAnimation[1] = 'selected';
    // console.log(this.projectTitleAnimation);
  }

  goProject(index) {
    const _index = Number(index);
    if (_index === 2) {
      const video1 = <HTMLMediaElement>document.getElementById('cover_2');
      video1.play();
    } else if (_index === 4 ) {
      const video2 = <HTMLMediaElement>document.getElementById('cover_4');
      video2.play();
    }

    if (!this.detail) {

      // console.log(index);

      this.step = index;

      if (this.step > (this.elementArray.length - 1)) {
        this.step = this.elementArray.length - 1;
      } else if (this.step < 0) {
        this.step = 0;
      }

      this.selector.nativeElement.style.top = this.titileContainerHeight * this.step + 100 + 'px';

      // console.log(this.step);

      for (let i = 0; i < this.elementArray.length; i++) {

        if (i < this.step) {
          this.elementArray[i] = 'left';
        } else if ( i > this.step ) {
          this.elementArray[i] = 'right';
        } else {
          this.elementArray[i] = 'center';
        }

      }

      setTimeout(() => {
        document.getElementById('cover_' + index).click();
      }, 1000);

    }
  }

  detailScroll(event) {
    // this.scrollPosition = event.target.scrollTop - this.detailContainer.nativeElement.offsetTop;
    // console.log(event.target.scrollTop);
    // const scrollpositon = event.target.scrollTop;
    const scrollPosition = event.target.scrollTop - this.detailContainer.nativeElement.offsetTop + (window.innerHeight / 2);
    let index = 0;
    // console.log(scrollPosition);
    // console.log(this.detailContainer.nativeElement.offsetTop);
    // console.log(this.detailScrollIndex);
    for (const position of this.detailScrollIndex) {
      if (scrollPosition > position) {
        index = this.detailScrollIndex.indexOf(position);
        // console.log(index);
      }
    }

    const part = 'part' + (index + 1);

    if (index >= 0) {
      // console.log(part);
      this._missionService.announceMission(part);
    }

  }

  updateIndexArray(value) {
    this.detailScrollIndex = value;
    // console.log(this.detailScrollIndex);
  }

  aboutButton() {
    if (this.about) {
      // console.log('close about');
      this.about = false;
      document.getElementById('side').style.border = 'solid 2px rgba(255, 255, 255, 0.2)';
    } else {
      if (this.detail) {
        document.getElementById('closeIcon_' + this.step).click();
        // console.log(document.getElementById('closeIcon_' + this.step));
        setTimeout(() => {
          this.about = true;
          document.getElementById('side').style.border = 'solid 2px rgba(255, 255, 255, 0)';
        }, 100);
      } else {
        this.about = true;
        document.getElementById('side').style.border = 'solid 2px rgba(255, 255, 255, 0)';
      }
    }

  }

  logoButton() {
    if (this.about) {
      // console.log('close about');
      this.about = false;
      document.getElementById('side').style.border = 'solid 2px rgba(255, 255, 255, 0.2)';
    }
    if (this.detail) {
      document.getElementById('closeIcon_' + this.step).click();
    }

  }

}
