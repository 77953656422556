import { Directive, ElementRef, HostListener, Input, OnInit, DoCheck} from '@angular/core';

@Directive({
  selector: '[appMovement]',

})

export class MovementDirective implements OnInit, DoCheck {
  constructor(
    private elRef: ElementRef
  ) {

  }
  // @Input() wheel: number;
  @Input() wiggleable: boolean;

  wiggleInterval;
  originalTop;
  originalLeft;

  @HostListener('mouseenter') onmouseenter() {
    if (this.wiggleable) {
      this.wiggleStart();
      this.changeColor('yellow');
    } else {
      // console.log('clearWiggle');
      clearInterval(this.wiggleInterval);
      this.elRef.nativeElement.style.transitionDuration = '0s';
      this.elRef.nativeElement.style.top = 0 + 'px';
      this.elRef.nativeElement.style.left = 0 + 'px';
    }
  }

  @HostListener('mouseleave') onmouseleave() {
    if (this.wiggleable) {
      clearInterval(this.wiggleInterval);
      this.elRef.nativeElement.style.transitionDuration = '0s';
      this.elRef.nativeElement.style.top = 0 + 'px';
      this.elRef.nativeElement.style.left = 0 + 'px';
    } else {
      // console.log('clearWiggle');
    }
  }

  @HostListener('mousewheel') mousewheel() {
    // // console.log(this.elRef.nativeElement.children[0].innerHTML = this.wheel);
  }

  ngDoCheck(): void {
    // // console.log('wiggle' + this.wiggleable);
  }

  ngOnInit(): void {
    // console.log('directive init');
    this.originalTop = this.elRef.nativeElement.offsetTop;
    this.originalLeft = this.elRef.nativeElement.offsetLeft;
  }

  changeColor(color) {
    this.elRef.nativeElement.style.backgroundColor = color;
  }


  wiggleStart() {
    // console.log('wiggle start');
      this.originalTop = this.elRef.nativeElement.offsetTop;
      this.originalLeft = this.elRef.nativeElement.offsetLeft;

      let randomTopFactor = Math.random() - 0.5;
      let randomLeftFactor = Math.random() - 0.5;

      this.elRef.nativeElement.style.transitionDuration = '3s';
      this.elRef.nativeElement.style.top = this.originalTop + (randomTopFactor * 30) + 'px';
      this.elRef.nativeElement.style.left = this.originalLeft + (randomLeftFactor * 30) + 'px';

      this.wiggleInterval = setInterval(() => {
        randomTopFactor = Math.random() - 0.5;
        randomLeftFactor = Math.random() - 0.5;
        this.elRef.nativeElement.style.top = this.originalTop + (randomTopFactor * 30) + 'px';
        this.elRef.nativeElement.style.left = this.originalLeft + (randomLeftFactor * 30) + 'px';
      }, 1000);

  }

  wiggleEnd() {
    // console.log('wiggle end');
      clearInterval(this.wiggleInterval);
      this.elRef.nativeElement.style.transitionDuration = '0.5s';
      this.elRef.nativeElement.style.top = this.originalTop + 'px';
      this.elRef.nativeElement.style.left = this.originalLeft + 'px';
  }
}
