import { Directive, ElementRef, HostListener, Input, OnInit, HostBinding} from '@angular/core';

@Directive({
  selector: '[appApplyCoverImage]',

})

export class ApplyCoverImageDirective implements OnInit {
    constructor(
        private elRef: ElementRef
      ) {}

    @Input() backgroundUrl;

    ngOnInit(): void {
        // console.log('apply cover image init');
        // console.log(this.backgroundUrl);
        setTimeout(() => {
            this.elRef.nativeElement.style.backgroundImage = 'url(' + this.backgroundUrl + ')';
        }, 0);

    }
}
