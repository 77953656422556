import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DetailPageComponent } from './detailPage/detailPage.component';
import { SidePageComponent } from './sidePage/sidePage.component';

import { AboutComponent } from './about/about.component';

import {AwsXrayComponent} from './awsXray/awsXray.component';
import {AwsXraySideComponent} from './awsXraySide/awsXraySide.component';
import {KitchenupComponent} from './kitchenup/kitchenup.component';
import {KitchenupSideComponent} from './kitchenupSide/kitchenupSide.component';
import {WeilaComponent} from './weila/weila.component';
import {WeilaSideComponent} from './weilaSide/weilaSide.component';
import {MeowwoofComponent} from './meowwoof/meowwoof.component';
import {MeowwoofSideComponent} from './meowwoofSide/meowwoofSide.component';
import {SwanlakeComponent} from './swanlake/swanlake.component';
import {SwanlakeSideComponent} from './swanlakeSide/swanlakeSide.component';

import { MovementDirective } from './directive/movement.directive';
import { CenterCenterDirective } from './directive/centerCenter.directive';
import { TraceMovementDirective } from './directive/traceMovement.directive';
import { ToggleSizeDirective } from './directive/toggleSize.directive';
import { DetailContainerMovementDirective } from './directive/detailContainerMovement.directive';
import { ApplyCoverImageDirective } from './directive/applyCoverImage.directive';
import { DragDirective } from './directive/drag.directive';

import { AppRoutingModule } from './app.routing';

import { MissionService } from './service/mission.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DetailPageComponent,
    SidePageComponent,
    AboutComponent,

    AwsXrayComponent,
    AwsXraySideComponent,
    KitchenupComponent,
    KitchenupSideComponent,
    WeilaComponent,
    WeilaSideComponent,
    MeowwoofComponent,
    MeowwoofSideComponent,
    SwanlakeComponent,
    SwanlakeSideComponent,

    MovementDirective,
    CenterCenterDirective,
    TraceMovementDirective,
    ToggleSizeDirective,
    DetailContainerMovementDirective,
    ApplyCoverImageDirective,
    DragDirective,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
  ],
  providers: [MissionService],
  bootstrap: [HomeComponent]
})
export class AppModule { }
