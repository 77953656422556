import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MissionService {

  // Observable string sources
  private missionAnnouncedSource = new Subject<any>();
  private missionConfirmedSource = new Subject<any>();
  private indexArrayAnnouncedSource = new Subject<any>();
  private weilaEmotionAnnouncedSource = new Subject<any>();

  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();
  indexArrayAnnouncedSource$ = this.indexArrayAnnouncedSource.asObservable();
  weilaEmotionAnnouncedSource$ = this.weilaEmotionAnnouncedSource.asObservable();

  // Service message commands
  announceMission(mission: any) {
    this.missionAnnouncedSource.next(mission);
    // console.log('sent event');
  }

  confirmMission(astronaut: any) {
    this.missionConfirmedSource.next(astronaut);
  }

  announceIndexArray(mission: any) {
    this.indexArrayAnnouncedSource.next(mission);
    // console.log('sent index array');
  }

  announceWeilaEmotion(mission: any) {
    this.weilaEmotionAnnouncedSource.next(mission);
    // console.log('sent weila emotion');
  }
}
