import { Component, Injectable, Input, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MissionService } from '../service/mission.service';

@Component({
  templateUrl: './weilaSide.component.html',
  styleUrls: ['./weilaSide.component.css']
})

@Injectable()

export class WeilaSideComponent implements OnInit {
  @Input() projectName;
  @Input() scrollPosition;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('part1') part1: ElementRef;
  @ViewChild('part2') part2: ElementRef;
  @ViewChild('part3') part3: ElementRef;
  @ViewChild('part4') part4: ElementRef;
  constructor(
    private elRef: ElementRef,
    private _missionService: MissionService,
  ) {
    _missionService.missionAnnounced$.subscribe(
      value => {
        // console.log('got event in sidePage');
        // console.log(value);

        this.scrollTo(value);
        if ( value === 'show') {

        } else if (value === 'hide') {

        }

      }
    );
  }

  show = 'hide';

  ngOnInit(): void {
    // console.log(this.projectName);
    setTimeout(() => {
      this.show = 'show';
    }, 10);

  }

  scrollTo(position) {
    // this.scrollContainer.nativeElement.scrollTo(0, position);
    // // let target;
    if (position === 'part1') {
      this.part1.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
    } else if (position === 'part2') {
      this.part2.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
    } else if (position === 'part3') {
      this.part3.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
    } else if (position === 'part4') {
      this.part4.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
    }
  }

}
