import { Directive, ElementRef, HostListener, Input, OnInit, DoCheck} from '@angular/core';

@Directive({
  selector: '[appDetailContainerMovement]',

})

export class DetailContainerMovementDirective implements OnInit, DoCheck {

    constructor(
        private elRef: ElementRef
    ) {

    }

    @Input() detailShow: boolean;

    ngDoCheck(): void {
        if (this.detailShow) {
            this.moveUp();
        } else {
            this.moveDown();
        }
    }

    ngOnInit(): void {
        // console.log('detail directive init');
        // console.log(this.detailShow);
    }

    moveUp() {
        this.elRef.nativeElement.style.top = '80vh';
    }

    moveDown() {
        this.elRef.nativeElement.style.top = '100vh';
    }
}
