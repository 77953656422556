import { Directive, ElementRef, HostListener, Input, OnInit, DoCheck} from '@angular/core';

@Directive({
  selector: '[appTraceMovement]',

})

export class TraceMovementDirective implements OnInit, DoCheck {
  constructor(
    private elRef: ElementRef
  ) {

  }

  originalTop;
  originalLeft;

  @Input() cursorPosition;
  @Input() target;
  @Input() traceable;


  @HostListener('mousemove') onmouseenter() {
    if (this.traceable) {
        this.traceCursor(this.cursorPosition);
    } else {
        this.elRef.nativeElement.children[this.target].style.transform = 'rotateX(0deg) rotateY(0deg)';
        this.elRef.nativeElement.children[this.target].style.top = (window.innerHeight - window.innerHeight * 0.4) / 2 + 'px';
        this.elRef.nativeElement.children[this.target].style.left = (window.innerWidth * 0.6 - window.innerWidth * 0.5) / 2 + 'px';

    }
  }


  ngDoCheck(): void {
    // // console.log('trace' + this.traceable);
  }

  ngOnInit(): void {
    // console.log('trace directive init');
    setTimeout(() => {
        this.originalTop = (window.innerHeight - window.innerHeight * 0.4) / 2;
        this.originalLeft = (window.innerWidth * 6 / 10 - this.elRef.nativeElement.children[this.target].offsetWidth) / 2;
        // console.log( this.originalTop);
    }, 100);


        window.onresize = () => {
            if (this.traceable) {
                this.originalTop = (window.innerHeight - window.innerHeight * 0.4) / 2;
                this.originalLeft = (window.innerWidth * 6 / 10 - this.elRef.nativeElement.children[this.target].offsetWidth) / 2;
                this.elRef.nativeElement.children[this.target].style.top = this.originalTop + 'px';
                this.elRef.nativeElement.children[this.target].style.left = this.originalLeft + 'px';
                // console.log( this.originalTop);
            }
        };

  }

  traceCursor(cursorPosition) {
    this.elRef.nativeElement.style.transitionDuration = '0s';

    if (cursorPosition.y - this.originalTop > (window.innerHeight * 0.4 / 2)) {

    //   // console.log('below');
      this.elRef.nativeElement.children[this.target].style.top = this.originalTop - (cursorPosition.y - this.originalTop) / 10 + 'px';
      this.elRef.nativeElement.children[this.target].style.transform = 'rotateX(-10deg)';

    } else if (cursorPosition.y - this.originalTop < (window.innerHeight * 0.4 / 2) && cursorPosition.y - this.originalTop >= 0) {

        this.elRef.nativeElement.children[this.target].style.top = this.originalTop + 'px';
        this.elRef.nativeElement.children[this.target].style.transform = 'rotateX(0deg)';

    } else if (cursorPosition.y - this.originalTop < 0) {
        // // console.log('above');
        this.elRef.nativeElement.children[this.target].style.top = this.originalTop + (this.originalTop - cursorPosition.y) / 10 + 'px';
        this.elRef.nativeElement.children[this.target].style.transform = 'rotateX(10deg)';
    }

    if (cursorPosition.x - this.originalLeft > (window.innerWidth * 0.5 / 2 ) ) {

        // // console.log('right');
        this.elRef.nativeElement.children[this.target].style.left = this.originalLeft - (cursorPosition.x - this.originalLeft) / 10 + 'px';
        // tslint:disable-next-line:max-line-length
        this.elRef.nativeElement.children[this.target].style.transform = this.elRef.nativeElement.children[this.target].style.transform  + 'rotateY(10deg)';

    } else if (cursorPosition.x - this.originalLeft < (window.innerWidth * 0.5 / 2 ) && cursorPosition.x - this.originalLeft >= 0) {

        this.elRef.nativeElement.children[this.target].style.left = this.originalLeft + 'px';
        // tslint:disable-next-line:max-line-length
        this.elRef.nativeElement.children[this.target].style.transform = this.elRef.nativeElement.children[this.target].style.transform  + 'rotateY(0deg)';

    } else if (cursorPosition.x - this.originalLeft < 0) {
        // // console.log('left');
        this.elRef.nativeElement.children[this.target].style.left = this.originalLeft + (this.originalLeft - cursorPosition.x) / 10 + 'px';
        // tslint:disable-next-line:max-line-length
        this.elRef.nativeElement.children[this.target].style.transform = this.elRef.nativeElement.children[this.target].style.transform  + 'rotateY(-10deg)';
    }

  }
}
